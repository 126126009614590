$(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
        $(".fixed-header").addClass("bg-header");
    } else {
        $(".fixed-header").removeClass("bg-header");
    }
});


$('.nav-link').on('click', function(event) {
	event.preventDefault();
    var target = "#" + this.getAttribute('data-target');
    $('html, body').animate({
        scrollTop: $(target).offset().top
    }, 700);
});

$('.main-header .navbar-dark .navbar-nav .nav-item').click(function(){
	$('.main-header .navbar-dark .navbar-nav .nav-item.active').removeClass('active');
	$(this).addClass('active');
});

	$(".billing-panel").each(function(){
		 $(this).hide();
		if($(this).attr('id') === 'billing-box-1') {
			$(this).fadeIn();
		}
	});
	$('.tab-bill').on( "click", function(e) {
		$('.tab-bill.active').removeClass('active');
		$(this).addClass('active');
		e.preventDefault();
		var id = $(this).attr('data-related');
		$(".billing-panel").each(function(){
			$(this).hide();
			if($(this).attr('id') === id) {
				$(this).fadeIn();
			}
		});
	});



//setTimeout(function() {
// $('.msg-validation').fadeIn();
// $('#email').val('');
//}, 10000 );


$(".height-terms-condition").each(function(){
	var myHeight = $(window).height() - 190;
		$(this).slimscroll({
		height: myHeight
	});
});
$('.chat-input').on('keyup',function() {
    $('.send-btn').show();
	$('.voice-msg').hide();
});
$('.chat-input').on('keyup', function()
{
    if( $(this).val() == '' ) {
        $('.send-btn').hide();
		$('.voice-msg').show();
    }
});
$('.chat-bot').click(function(){
	$('.chat-window').show();
});
$('.close-chat').click(function(){
	$('.chat-window').hide();
});


$('.btn-add-utility').click(function(){
	$('.all-bills, .single-bill, .table-billing, .quick-pay-section').hide();
	$('.add-utility-section').show();
});
$('.btn-quick-pay').click(function(){
	$('.all-bills, .single-bill, .table-billing, .add-utility-section').hide();
	$('.quick-pay-section').show();
});
$('.btn-back-all-bills, .btn-back-quick-pay-process').click(function(){
	$('.all-bills, .table-billing').show();
	$('.quick-pay-section, .single-bill, .add-utility-section').hide();
});
$('.back-sign .btn-back-all-bills').click(function(){
	$('.login-page .quick-pay-section').show();
});




$('.btn-next-process').click(function(){
	$('.process-step-1').removeClass('none-pointer-event');
	$('.quick-pay-process ul li.process-step-1').addClass('complete-process');
	$('.quick-pay-process ul li.process-step-2 .process-steps-main').addClass('active');
	$('.step-1, .form-main-login').hide();
	$('.step-2, .welcome-content').fadeIn();
	$('.process-back-1').fadeIn();
});
$('.process-step-1').click(function(){
	$('.process-step-1').addClass('none-pointer-event');
	$('.quick-pay-process ul li.process-step-1').removeClass('complete-process');
	$('.quick-pay-process ul li.process-step-2 .process-steps-main').removeClass('active');
	$('.step-1, .form-main-login').show();
	$('.step-2, .welcome-content').hide();
	$('.process-back-1').hide();
});
$('.btn-process-quick-pay').click(function(){
	$('.process-step-2').removeClass('none-pointer-event');
	$('.quick-pay-process ul li.process-step-2').addClass('complete-process');
	$('.quick-pay-process ul li.process-step-3 .process-steps-main').addClass('active');
	$('.step-2, .process-back-1').hide();
	$('.step-3').fadeIn();
	$('.process-back-2').fadeIn();
});
$('.process-step-2').click(function(){
	$('.process-step-2').addClass('none-pointer-event');
	$('.quick-pay-process ul li.process-step-2').removeClass('complete-process');
	$('.quick-pay-process ul li.process-step-3 .process-steps-main').removeClass('active');
	$('.step-2, .process-back-1').show();
	$('.step-3').hide();
	$('.process-back-2').hide();
});


if($(window).innerWidth() <= 767) {

	$('.mobile-close').hide();
	$('.sidebar-left').removeClass('left-55');
	$('.sidebar-left').addClass('small-sidebar');
	$('.main-section').removeClass('open-sidebar');
	$('.profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown').hide();
	$('.side-menu ul li a').addClass('text-center');

  $('.btn-toggle-sidebar').click(function(){
	$('.mobile-close').show();
	$('.sidebar-left').toggleClass('left-55');
	$('.sidebar-left').toggleClass('small-sidebar');
	$('.main-section').toggleClass('open-sidebar');
	$('.profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown').toggle();
	$('.side-menu ul li a').removeClass('text-center');
});
	$('.mobile-close').click(function(){
	$(this).hide();
	$('.sidebar-left').toggleClass('left-55');
	$('.sidebar-left').toggleClass('small-sidebar');
	$('.main-section').toggleClass('open-sidebar');
	$('.profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown').toggle();
	$('.small-sidebar .side-menu ul li a').addClass('text-center');
});

} else {

   $('.btn-toggle-sidebar').click(function(){
	$('.customer-dashboard').toggleClass('minus-margin');
	$('.change-md-col').toggleClass('col-md-6');
	$('.change-md-col').toggleClass('col-md-12');
	$('.sidebar-left').toggleClass('left-55');
	$('.sidebar-left').toggleClass('small-sidebar');
	$('.main-section').toggleClass('open-sidebar');
	$('.profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown').toggle();
});
}





$('.bill-box .title-bill, .bill-box .logo, .bill-box .full-view').click(function(){
	$('.all-bills').hide();
	$('.single-bill').show();
});





